import { useEffect,useState } from 'react'

//mui
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogContent from '@mui/material/DialogContent'
import DialogActions from '@mui/material/DialogActions'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import { Iinfo } from '../../types/interface'

//* Alertbars / Snackbars 
interface Isnack {
 
    openYesNoDialog: boolean
    setOpenYesNoDialog: (p: boolean) => void
    //setLoading: (props:boolean)=> void
    submitFunc: ()=> Promise<void>
    info: Iinfo
}
const YesNoDialog = (props:Isnack) => {
    const { submitFunc, openYesNoDialog,setOpenYesNoDialog, info } = props


    const [loading, setLoading] = useState(false)

    const Submit = async() =>{
        setLoading(true)
        await submitFunc()
        setLoading(false)
        setOpenYesNoDialog(false)
    }
    

    //*  Open Close Delete and update Dialog    
    const CloseDialog = () => {      
        if(!loading){
            setOpenYesNoDialog(false)
        }
        
    };   
 
   

    return (
        <Dialog
        open={openYesNoDialog}
        onClose={CloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {info?.YesNo?.YesNoDialog_Title}
        </DialogTitle>
        <DialogContent>                
            <DialogActions className='row__to__column_300'>             

                <Button disabled={loading} variant="outlined"  onClick={CloseDialog} sx={{width: '50%'}}>
                    {info?.YesNo?.YesNoDialog_Cancel}
                </Button>

                <Button
                    sx={{width: '50%'}}
                    variant="contained"
                    color='error'
                    disabled={loading}
                    onClick={Submit}                    
                >
                {info?.YesNo?.YesNoDialog_Accept}
                {loading && (
                    <CircularProgress
                        size={24}
                        sx={{
                        color: "white",
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                        }}
                    />
                )}
                </Button>

            </DialogActions>
        </DialogContent>    
    </Dialog>

    )
}

export default YesNoDialog