import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit'
import axios from 'axios'

import {jwtDecode} from 'jwt-decode'




const serverAddress = process.env.NODE_ENV === "production" ? `https://api.${process.env.REACT_APP_COMPONENT}.se` : "http://localhost:5000"


interface decodedAccTokenInit  {
    exp: number
    iat: number
    userRole: string
    tokenId: string
    userId: string
    email: string
    profileImage: string
}

//* Info
export const UpdateInfo = createAsyncThunk(
    "put/UpdateInfo/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updateinfo`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetInfoDb = createAsyncThunk(
    "get/GetInfoDb/admin",
    async(info: any,{ getState } ) => {
        try {                           
            const res = await axios.get(`${serverAddress}/getinfo`)        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetEditInfo = createAsyncThunk(
    "get/GetEditInfo",
    async(info: any,{ getState } ) => {
        try {                           
            const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/info/editinfo.json`,
                {
                    params:{ t: new Date().getTime()} // removes cache ??
                }
            )
            return { body: {data: res.data}, statusCode: res.status}

        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})


//* News
export const NewsUploadImg = createAsyncThunk(
    "put/NewsUploadImg/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.post(`${serverAddress}/newsuploadimg`,info )        
            return res?.data
        } catch (error) {
            //console.log(error)   
           return  {statusCode:400}
        }
})
export const AddNews = createAsyncThunk(
    "put/AddNews/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.post(`${serverAddress}/addnews`,info )        
            return res?.data
        } catch (error) {
            //console.log(error)   
           return  {statusCode:400}
        }
})
export const UpdateNews = createAsyncThunk(
    "put/UpdateNews/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatenews`,info )        
            return res?.data
        } catch (error) {
            //console.log(error)   
           return  {statusCode:400}
        }
})
export const GetNews = createAsyncThunk(
    "get/GetNews",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`${serverAddress}/getnews/`)        
        return res?.data
    } catch (error) {
        //console.log(error)   
       return  {statusCode:400}
    }
})
export const DeleteNews = createAsyncThunk(
    "get/DeleteNews/admin",
    async(info: any,{ getState } ) => {                
    try {

        const state:any = getState()
        
        let token = state?.token?.AccToken                  
        const res = await axios.delete(`${serverAddress}/deletenews/?pk=${info?.pk}&AccToken=${token}`)
        return res.data
    } catch (error) {
        //console.log(error)   
       return  {statusCode:400}
    }
})

//* Staff
export const UpdateStaff = createAsyncThunk(
    "put/UpdateStaff/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updatestaff`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})
export const GetStaff = createAsyncThunk(
    "get/GetStaff",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/staff/staff.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const DeleteStaff = createAsyncThunk(
    "get/DeleteStaff/admin",
    async(info: any,{ getState } ) => {                
    try {

        const state:any = getState()        
        info["AccToken"] = state?.token?.AccToken                  
        const res = await axios.put(`${serverAddress}/deletestaff`,info)
        return res.data
    } catch (error) {
        //console.log(error)   
       return  {statusCode:400}
    }
})

//* Gallery
export const GetGallery = createAsyncThunk(
    "get/GetGallery",
    async(info: any,{ getState } ) => {                
    try {
        
        const res = await axios.get(`https://${process.env.REACT_APP_CDN_URL}/gallery/gallery.json`,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        }
    )        
        return { body: {data: res.data}, statusCode: res.status}
    } catch (error) {
        console.log(error)   
        return {statusCode:400}
    }
})
export const UpdateGallery = createAsyncThunk(
    "put/UpdateGallery/admin",
    async(info: any,{ getState } ) => {
        try {
            const state:any = getState()
        
            info["AccToken"] = state?.token?.AccToken  
            const res = await axios.put(`${serverAddress}/updategallery`,info )        
            return res?.data
        } catch (error) {
            console.log(error)   
           return  {statusCode:400}
        }
})




//* User
export const Login = createAsyncThunk(
    "post/Login/admin",    
    async(info: any,{ getState } ) => {             
    try {
        
        const res = await axios.post(`${serverAddress}/login`,info,{withCredentials:true})
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const Logout = createAsyncThunk(
    "delete/Logout/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()        
        
        let decodedAccToken:any = jwtDecode(state?.token?.AccToken  );  
    
        const res = await axios.delete(`${serverAddress}/logout/?userId=${decodedAccToken?.userId}` ,{withCredentials:true})        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const UpdateProfileImage = createAsyncThunk(
    "put/UpdateProfileImage/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()                        
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updateprofileimage`, info,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        })        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const UpdatePassword = createAsyncThunk(
    "put/updatepassword/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()                        
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/updatepassword`, info,
        {
            params:{ t: new Date().getTime()} // removes cache ??
        })        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const CreateAccount = createAsyncThunk(
    "post/CreateAccount/admin",    
    async(info: any,{ getState } ) => {             
    try {
        const state:any = getState()                        
        info["AccToken"] = state?.token?.AccToken  
        const res = await axios.put(`${serverAddress}/createaccount`, info)        
   
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const RefreshToken = createAsyncThunk(
    "get/RefreshToken/admin",
    async(info: any,{ getState } ) => {                
    try {
        const res = await axios.get(`${serverAddress}/refreshtoken/`,{ withCredentials: true })
        return res.data
    } catch (error) {
        console.log(error)   
       return  {statusCode:400}
    }
})
export const ForgotPassword = createAsyncThunk(
    "get/ForgotPassword",    
    async(info: any,{ getState } ) => {                                     
        const res = await axios.post(`${serverAddress}/forgotpassword`,info )        
        return res.data
})
export const ConfirmForgotPassword = createAsyncThunk(
    "get/ConfirmForgotPassword",    
    async(info: any,{ getState } ) => {                                     
        const res = await axios.put(`${serverAddress}/confirmforgotpassword`,info )        
        return res.data
})


interface Itokenreducerstate {
    AccToken: string 
    user: {
        email: string
        userId: string
        profileImage: string
        userRole:string
    }
    status: string
    refreshingToken: boolean
}
interface IAction {
    statusCode: number
    body: Itokenreducerstate
}
const initaluser = { email:"", userId:"", profileImage:"", userRole:""}
const initalStateValue:Itokenreducerstate = { AccToken:"" , user: initaluser, status: "inital", refreshingToken: false}


const postSlice = createSlice({
    name:"token",
    initialState: initalStateValue,
    reducers:{},
    extraReducers: builder => {
        builder.addCase(RefreshToken.pending,(state, action) => {
            state.refreshingToken = true;                        
        })
        builder.addCase(RefreshToken.fulfilled,(state, action) => {
            state.refreshingToken = false;
            if(action?.payload?.statusCode === 200){
                state.AccToken = action?.payload?.body?.AccToken
                let decodedAccToken:decodedAccTokenInit = jwtDecode(action?.payload?.body?.AccToken);
                
                state.user = {
                    userId: decodedAccToken?.userId,    
                    userRole: decodedAccToken?.userRole, 
                    email: decodedAccToken?.email, 
                    profileImage: decodedAccToken?.profileImage
                }
            }else{
                state.AccToken = ""
            }
            
        })
        builder.addCase(RefreshToken.rejected,(state, action) => {
            state.refreshingToken = false;            
            state.AccToken = ""
            
            
        })
      
        builder.addCase(Logout.rejected,(state, action) => {
            state.AccToken = ""
            state.user = initaluser                        
        }) 
        builder.addCase(Logout.fulfilled,(state, action) => {
            state.AccToken = ""
            state.user = initaluser                       
        }) 
        builder.addCase(UpdateProfileImage.fulfilled,(state, action) => {                                            
            state.user = action?.payload?.body?.data            
            state.AccToken = action?.payload?.body?.AccToken                        
        }) 


        builder.addCase(Login.fulfilled,(state, action) => {            
           
            try {                            
                let decodedAccToken:decodedAccTokenInit = jwtDecode(action?.payload?.body?.AccToken);
             
                state.user = {
                    userId: decodedAccToken?.userId,    
                    userRole: decodedAccToken?.userRole, 
                    email: decodedAccToken?.email, 
                    profileImage: decodedAccToken?.profileImage
                }

            } catch (error) {
                state.AccToken = ""
                state.user = initaluser
            }
        }) 
        
        builder.addMatcher((action) => action.type.endsWith('/pending'),(state,action)=>{                          
            state.status = 'loading'
        })  
        builder.addMatcher((action) => action.type.endsWith('/fulfilled'),(state,action)=>{                             
            state.status = 'succeeded'
        })  
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{                          
            state.status = 'failed'
        })
        //AddMatcher triggers on all /fulfilled in this case
        builder.addMatcher((action) => action.type.endsWith('/admin/fulfilled'),(state,action:PayloadAction<IAction>)=>{               
            if(action?.payload?.statusCode === 401){
                //return {AccToken: "", user:{}, status: "succeeded"}
                state.AccToken = ""
                state.user = initaluser
                
            }           
            if(action?.payload?.body?.AccToken && !action.type.startsWith('put/UpdateProfileImage') ){
              
                try {                                    
                    let decodedAccToken:decodedAccTokenInit = jwtDecode(action?.payload?.body?.AccToken); 
                
                    state.AccToken = action?.payload?.body?.AccToken
                    
                    state.user = { 
                        userId: decodedAccToken?.userId,
                        userRole: decodedAccToken?.userRole, 
                        email: decodedAccToken?.email, 
                        profileImage: decodedAccToken?.profileImage
                    }
                } catch (error) {
                    state.AccToken = ""
                    state.user = initaluser
                }
                //return {AccToken : action?.payload?.body?.AccToken , user: { userId: decodedAccToken?.userId, role: decodedAccToken?.userRole}, status: "succeeded"}
            }
           
           
        })        
        builder.addMatcher((action) => action.type.endsWith('/rejected'),(state,action)=>{      
            /*    if(action?.payload?.statusCode === 401){
                   return {AccToken: ""}
               }           
               if(action?.payload?.body?.AccToken){
                   return {AccToken : action?.payload?.body?.AccToken}                                
               }
               return */
              return
           })        
    }
})

const { reducer } = postSlice

export default reducer

 