import { useState } from 'react';

// mui
import { alpha } from '@mui/material/styles';

import Box from '@mui/material/Box';

import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';

import Toolbar from '@mui/material/Toolbar';

/* Data grid imports */
import {  DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';

import DeleteIcon from '@mui/icons-material/Delete';

import { Iinfo} from '../../types/interface';

interface Iprops {
    info: Iinfo
    rows: Array<{}>
  
    columns: GridColDef []
    rowSelectionToogle: boolean
   
}
const UpdateTable = (props:Iprops) => {
    const { rows, columns, info, rowSelectionToogle} = props

    const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
    
    const RemoveItems = () => {
       /*  let tempRows:Iservice [] = structuredClone(rows)            
        rowSelectionModel?.forEach(tempId => {

            let rowIndex = tempRows?.findIndex( r => r?.id === tempId)
            let removedItem = tempRows.splice( rowIndex, 1)[0]

            
            setRemove(prev => [...prev, removedItem])
            
            
        })

        setRows(tempRows) */
    }
    
    return(
        <Box sx={{width:"100%"}}>
            {/* Toolbar with Name of the table, selected items and delete button */}
            <Toolbar
        
                sx={{
                pl: { sm: 2 },
                pr: { xs: 1, sm: 1 },
                ...(rowSelectionModel.length > 0 && {
                    bgcolor: (theme) =>
                    alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                }),
                }}
            >
                {rowSelectionModel.length > 0 ? (
                <Typography
                    sx={{ flex: '1 1 100%' }}
                    color="inherit"
                    variant="subtitle1"
                    component="div"
                >
                    {rowSelectionModel.length} {info?.Service?.Button_SelectImage}
                    
                </Typography>
                
                ) : (
                <Box className="flex__center-r">
                        
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        variant="h5"
                        id="tableTitle"
                        
                    >
                        {info?.Service?.MainTitle}
                    
                    </Typography>
                
                </Box>
                
                
                )}
    
                {rowSelectionModel.length > 0 && (
                    <Tooltip title={info?.Service?.DeleteTooltip}>
                        <IconButton  onClick={RemoveItems}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                )}

            </Toolbar>
        
            <DataGrid                
                rows={rows || [] }
                columns={columns}
                initialState={{
                    pagination: {
                        paginationModel: {
                        pageSize: 5,
                        },
                    },
                }}
                pageSizeOptions={[5,10,100]}
                checkboxSelection={rowSelectionToogle}
                disableRowSelectionOnClick={!rowSelectionToogle}
                onRowSelectionModelChange={(newRowSelectionModel) => {
                    setRowSelectionModel(newRowSelectionModel);
                }}
                rowSelectionModel={rowSelectionModel}
            
        
            
            />
        </Box>
    )
    
}

export default UpdateTable