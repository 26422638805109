import React, { useRef, useCallback } from 'react'
import { createReactEditorJS } from "react-editor-js";
import { OutputData } from "@editorjs/editorjs";
import Resizer from "react-image-file-resizer";
import Box from '@mui/material/Box'
import { v4 as uuidv4 } from "uuid"


//redux
import { AppDispatch } from "../../store";
import { useDispatch } from "react-redux";
// @ts-ignore
//import Paragraph from 'editorjs-paragraph-with-alignment'

import Paragraph from "@editorjs/paragraph";
// @ts-ignore
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune'
// @ts-ignore
import Embed from "@editorjs/embed";
// @ts-ignore
import Table from "@editorjs/table";
// @ts-ignore
import List from "@editorjs/list";
// @ts-ignore
import Warning from "@editorjs/warning";
// @ts-ignore
import Code from "@editorjs/code";
// @ts-ignore
import LinkTool from "@editorjs/link";
// @ts-ignore
import Image from "@editorjs/image";
// @ts-ignore
import Raw from "@editorjs/raw";

import Header from "@editorjs/header";
// @ts-ignore
import Quote from "@editorjs/quote";
// @ts-ignore
import Marker from "@editorjs/marker";
// @ts-ignore
import CheckList from "@editorjs/checklist";
// @ts-ignore
import Delimiter from "@editorjs/delimiter";
// @ts-ignore
import InlineCode from "@editorjs/inline-code";

import { Iimage, Iinfo } from '../../types/interface';
import { NewsUploadImg } from '../../slices/tokenReducer'
import { TokenRefresh } from '../../functions/Token';
const width = 1080
const height = 1080
const type = "WEBP"
const quality = 100
const cdn_adress = `https://${process.env.REACT_APP_CDN_URL}`
interface Iprops {
    id: string
    info: Iinfo
    category: string
    data: OutputData
    setData: (p:React.SetStateAction<OutputData>) => void
    readOnly: boolean
}

const EditorJsComponent = (props:Iprops) => {
    const { info, category, data, setData ,readOnly, id } = props;

    const dispatch: AppDispatch = useDispatch()
    const editorCore:any = useRef(null);
	const ReactEditorJS = createReactEditorJS();

    //const [ data, setData] = useState<OutputData>(inputData)

    const handleInitialize = useCallback((instance:any) => {
        try {
            
        
		// await instance._editorJS.isReady;
		instance._editorJS.isReady
			.then(() => {
				// set reference to editor
				editorCore.current = instance;
			})
			.catch((err: Error) => console.log("An error occured", err));
        } catch (error) {
            console.log(error)   
        }
	}, []);
    const handleSave = useCallback(async () => {
		// retrieve data inserted
        try {        
            
                const savedData = await editorCore.current.save();      
                setData(savedData);
              
        } catch (error) {
            console.log(error)
        }
        
		
	}, [setData]);
    const resizeFile = (file:any) =>
    new Promise((resolve) => {
       
    Resizer.imageFileResizer(
        file,
        width,            // max width
        height,            //max height
        type,         // file output
        quality,             // 100 best quality, 50, middle, 1 lowest quality
        0,
        (uri) => {
        resolve(uri);
        },
        "base64"
    );
    });
    const onDrop = async (acceptedFiles:any) => {      
        try {          
            
            
            const file = acceptedFiles
            const compressedImage:any = await resizeFile(file);
            const type_lowercase = type.toLowerCase()
            const idTemp = `${uuidv4()}_${id}`
            let newFile = {
                key: `${category}/${idTemp}.${type_lowercase}`,
                file: compressedImage,
                type: `image/${type_lowercase}`
            }
                                               
            
            return newFile
            
            
        } catch (error) {           
            //setMessage(info?.Gallery?.FileError)
            //setAlert("error")                
        }  
    }
    const UploadImage = async(imgFile:Iimage) => {
        const result = await TokenRefresh()
        if(result !== 200){
            return {
                success:0,
                file: {
                    url: ""
                }
            }
        }
        const dispatchRes = await dispatch(NewsUploadImg({newImage:imgFile}))
        .unwrap()
        .then((res)=>{
            
            if(res?.statusCode === 200){
                return {
                    success:1,
                    file: {
                        url: `${cdn_adress}/${res?.body?.url}`
                    }
                }
            }else{
                return {
                    success:0,
                    file: {
                        url: ""
                    }
                }
            }            
        })
        return dispatchRes
    }

    
    return (
        <Box>
        {data?.time !== undefined &&
            <ReactEditorJS
                onInitialize={handleInitialize}
                tools={{
                    // NOTE: Paragraph is default tool. Declare only when you want to change paragraph option.
                    //paragraph: Paragraph
                 /*    paragraph: {
                        class: Paragraph,
                        inlineToolbar: true,
                        tunes: ["anyTuneName"]
                    }, */
                    embed: Embed,
                    table: Table,
                    list: List,
                    //warning: Warning,
                    code: Code,
                    linkTool: LinkTool,
                    image: {
                        class: Image,
                        config: {
                            endpoints:{
                            // byFile: serverAddress,
                                //byUrl: "http://localhost:5000/kuk"//serverAddress}
                            },
                            uploader:{
                                uploadByFile: async(file:any)=> {
                            
                                    let temp = await onDrop(file)//structuredClone(file)
                            
                                    if(temp){
                                        const result = await UploadImage(temp)
                                        return result
                                    }else{
                                        return {
                                            success:0,
                                            file: {
                                                url: ""
                                            }
                                        }
                                    }
                                    
                                    
                                }
                            },
                        
                        }
                    },
                    raw: Raw,
                    header: Header,
                    //quote: Quote,
                    marker: Marker,
                   // checklist: CheckList,
                    delimiter: Delimiter,
                    inlineCode: InlineCode,
                 
                
                // simpleImage: SimpleImage
                }}
                onChange={handleSave}
                defaultValue={data}                  
                inlineToolbar={["bold","italic","marker","inlineCode"]}
                //value={data}                                                
                readOnly={readOnly}
                
            />
        }
        </Box>
    )
}

export default EditorJsComponent