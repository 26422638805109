import { useEffect, useState } from 'react';
import { OutputData } from "@editorjs/editorjs";
// mui
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';

import Select from '@mui/material/Select';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

//redux
import  { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'
import { UpdateNews } from '../../slices/tokenReducer'; 

// self
import { TokenRefresh } from '../../functions/Token';
import { SnackbarComponent, DragDrop, EditorJsComponent } from '../index'
import { Iinfo, Iimage, Inewsitem } from '../../types/interface';


const categories = [    
    "crossfit",
    "bodybuilding",
    "cardio",
    "kost",
    "övrigt",    
]
interface Iupdatedialog {             
    updateDialog: boolean
    CloseUpdateDialog: () => void
    row: Inewsitem   
    info: Iinfo
    setReFetch:(p:React.SetStateAction<boolean>) => void
   
}
const UpdateDialogNews = (props:Iupdatedialog) => {
    const { updateDialog, CloseUpdateDialog, row, info,setReFetch } = props;

    const dispatch: AppDispatch = useDispatch()
    
    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [ updateRow, setUpdateRow ] = useState(row)
    const [ updateBlock, setUpdateBlock ] = useState<OutputData>({time:row?.time, blocks: row.blocks} as OutputData)

    const [ thumbnail, setThumbnail ] = useState<Iimage>({key: updateRow?.thumbnail, file:"",type:""})
    
    console.log("thumbnail",thumbnail)
               
    useEffect(() => {
        setUpdateRow(row)
        setUpdateBlock({time:row?.time, blocks: row.blocks})
        setThumbnail({key: row?.thumbnail, file:"",type:""})   
        return () => {}
    }, [row])

    const HandleTextFields = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event?.target
        setUpdateRow(prev => 
            ({
                ...prev, [name]: value
            }))
    }             
    const UpdateAPI = async() => {
        try{
            setLoadingUpdate(true)                      
            const tokenres = await TokenRefresh()          
            if(tokenres !== 200) {           
                setLoadingUpdate(false)
                return
            }        

            const tempData = structuredClone(updateRow)
            tempData["blocks"] = updateBlock?.blocks
            await dispatch(UpdateNews({                 
                updateInformation:tempData,                              
                newImage: thumbnail,                    
            }))
                .unwrap()
                .then(res => {
                    if(res?.statusCode === 200 ){
                        
                        setAlert("success")
                        setMessage(info?.News?.Update_Success)
                        setLoadingUpdate(false)
                  
                        
                        //setAllNewsItems(prev => prev.filter(item => item.pk !== tempData?.pk ? tempData : item ) )
                        
                        
                        setTimeout(() => {
                            setReFetch(prev => !prev)
                            CloseUpdateDialog()                            
                            setAlert("")
                            setMessage("")
                        }, 2000); 
                      
                    }else{   
                        setLoadingUpdate(false)
                        setAlert("error")
                        setMessage(res?.body?.message ||  info?.News?.Update_Error)                                                                                                    
                    }                        
                })                                                                                        
        }
        catch (error) {
            setAlert("error")
            setMessage(info?.News?.Update_Error)
            setLoadingUpdate(false)
    
        }
      
    }

    
    return (                       
        <Dialog
          open={updateDialog}
          onClose={CloseUpdateDialog}
          sx={{
            "& .MuiDialog-container": {
              "& .MuiPaper-root": {
                width: "100%",
                maxWidth: "50rem",  // Set your width here
              },
            },
          }}
          
       
        >         
            <DialogTitle className="flex__center-r" sx={{justifyContent:"space-around"}}>

                <Typography variant="h4" >
                    {info?.News?.Update_Title}
                </Typography>

                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={CloseUpdateDialog}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>


            <DialogContent >
                                                      
                    <Box className="flex__center-c"  >
                  

                        {/* Submit Button */}
                        <Button
                            sx={{width: '180px'}}
                            variant="contained"
                            color='success'
                            type='submit'
                            disabled={loadingUpdate}
                            onClick={UpdateAPI}
                            endIcon={<SaveIcon />}
                        >
                            {info?.News?.Button_SaveChanges}
                            {loadingUpdate && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "white",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                        )}
                        </Button>

                        <TextField       
                            required                         
                            label={`Title`}
                            sx={{m:"0.5rem 0",label:{color:"grey.400"}}}                                                                                 
                            multiline
                            fullWidth 
                            maxRows={4}
                            name={`title`}
                            value={updateRow?.title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                HandleTextFields(event);
                            }}
                        />
                        <TextField    
                            required                          
                            label={`Info`}
                            sx={{m:"0.5rem 0",label:{color:"grey.400"}}}
                            multiline
                            fullWidth                             
                            name={`intro`}
                            value={updateRow?.intro}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                HandleTextFields(event);
                            }}
                        />
                     
                        <Typography>{info?.News?.DragDropLabel}</Typography>
                        <DragDrop setMessage={setMessage} setAlert={setAlert} setImage={setThumbnail} info={info} image={ thumbnail } id={ updateRow?.id }
                            category={"news"}
                            width={500}
                            height={500} 
                            type={"WEBP"} 
                            quality={80} 
                        />

                        <EditorJsComponent
                            id={row?.pk}
                            readOnly={false}
                            data={updateBlock}
                            setData={setUpdateBlock}
                            info={info}
                            category={"news"}
                        />

                   
                                                                                       
                    </Box>                    
               
            </DialogContent>    


            {/* Alertbars / Snackbars  */}
            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />

          
        </Dialog>
    )
} 

export default UpdateDialogNews