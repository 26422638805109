import { useState, useEffect } from 'react'

// mui
import Box from '@mui/material/Box'


// REDUX
import { InfoTable } from '../../components/index'
import { useDispatch } from 'react-redux'
import { GetInfoDb } from '../../slices/tokenReducer'; 
import { AppDispatch } from '../../store'


import { Iinfo, Ieditinfo, Iinfodb } from '../../types/interface'


interface Iprops {
    info: Iinfo
    editInfo: Ieditinfo
}
const Info = (props:Iprops) => {
    const { info, editInfo } = props
  
    const dispatch: AppDispatch = useDispatch()

    const [infoDb, setInfoDb] = useState<Iinfodb>({} as Iinfodb)
    const [tab, setTab] = useState(0)

    // Changes tab between pdf creator and upload
    const ChangeTab = (event: React.SyntheticEvent, newValue: number) => {
        setTab(newValue);
    };

    useEffect(() => {
       
  /*       dispatch(GetInfoDb({}))
            .unwrap()
            .then(res => {
         
                if(res?.statusCode === 200){
                    setInfoDb(res?.body?.data ||{} as Iinfo)
                    
                }
                
            }) */

            
            

        return () => {
        
        }
    }, [])


    return (
        <Box className="flex__center-c" justifyContent="flex-start">

          {/*   <Tabs value={tab} onChange={ChangeTab} centered textColor='primary' indicatorColor="primary">
                <Tab label={info?.Pdf?.Tab_Create} sx={{color:"primary.main",}}/>
                <Tab label={info?.Pdf?.Tab_Upload} sx={{color:"primary.main",}}/>
                <Tab label={info?.Pdf?.Tab_Delete} sx={{color:"primary.main",}}/>
            </Tabs> */}


            <InfoTable editInfo={editInfo} info={info} infoFromDb={infoDb} />

        </Box>
    )
}

export default Info