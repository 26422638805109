import { useState } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import CircularProgress from '@mui/material/CircularProgress';

// REDUX
import { Login } from '../../slices/tokenReducer'; 
import  store  from '../../store'
import { useDispatch } from 'react-redux'
import { Iinfo } from '../../types/interface';



interface Iprops {
    info: Iinfo | undefined
}
const SignIn = (props:Iprops) => {
    const { info } = props;
    
    type AppDispatch = typeof store.dispatch
	const useAppDispatch = () => useDispatch<AppDispatch>()
	const dispatch = useAppDispatch()  
    const [loading, setLoading] = useState(false)
  
    const [message, setMessage] = useState("")




    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        setMessage("")
        setLoading(true)
        const data = new FormData(event.currentTarget);
        dispatch(Login({email:  data.get('email') , password:  data.get('password') }))                    
        //dispatch(Login({email: "" , password: "" }))
        .unwrap()
        .then((res:any) => {
            
            if(res?.statusCode === 200){
                setMessage("")
                
            }else if(res?.body?.message){
                setMessage(res?.body?.message)
            }else{
                setMessage("Ett oväntat fel uppkom, försök igen")
            }
            setLoading(false)
        })  

    }


  return (
   
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <Box
                sx={{
                    marginTop: 8,
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    {info?.SignIn?.Button_SignIn}
                </Typography>
                <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="Email Address"
                        name="email"
                        autoComplete="email"
                        autoFocus
                    />
                    <TextField
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="Password"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" />}
                        label="Remember me"
                    />

                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3, mb: 2 }}                                
                        disabled={loading}                                
                    >
                        {info?.SignIn?.Button_SignIn}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                    color: "teal",
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    marginTop: '-12px',
                                    marginLeft: '-12px',
                                }}
                            />
                        )}
                    </Button>
                    <Typography component="h6" variant="h1" sx={{fontSize:"15px",textAlign:"center"}}>
                    {message}
                    </Typography>

                    

                    <Grid container>
                    <Grid item xs>
                        <Link href="#" variant="body2">
                        {info?.SignIn?.Button_ForgotPassword}
                        </Link>
                    </Grid>
                    <Grid item>
                      
                    </Grid>
                    </Grid>
                </Box>
            </Box>
          
      </Container>
 
  );
}


export default SignIn