import { configureStore, combineReducers } from "@reduxjs/toolkit";
import tokenReducer from './slices/tokenReducer'
import { createStateSyncMiddleware, withReduxStateSync,initStateWithPrevTab } from 'redux-state-sync';

export type AppDispatch = typeof store.dispatch

const reducer = combineReducers({   
    token: tokenReducer,

})
const rootReducer = withReduxStateSync(reducer);
const extraMiddlewares:any = [createStateSyncMiddleware({    })]
const store = configureStore({
    reducer:rootReducer,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware({
        serializableCheck: {
          ignoredActions: [/* if you needed */],
        },
      }).concat(extraMiddlewares),
})

initStateWithPrevTab(store);

export default store

