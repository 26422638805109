import { useState, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom'

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import './App.css';

/* dashboard */
import { createTheme, ThemeProvider,responsiveFontSizes } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import CircularProgress from '@mui/material/CircularProgress';


// REDUX
import { GetEditInfo } from './slices/tokenReducer'; 
import { AppDispatch } from './store'
import { useDispatch, useSelector } from 'react-redux'


import { Navbar } from './components/index';
import { Welcomepage, Account, Staff, Info, News, SignIn, Gallery } from './containers/index';
import { TokenRefresh } from './functions/Token';
import { UserRoles2, UserRoles3 } from './functions/UserRoles';
import { Iinfo, Ieditinfo } from './types/interface';


export let theme = createTheme({    
    palette: {
        primary: {
            main: '#0073b5',                       
            dark: '#005393' ,
            light: "#80d1f5",
            contrastText:"#ffffff"
        
            
        },
        secondary: {
            main: '#b64600',
            light:"#b64600",
            dark:"#3c70d7",
            contrastText:"#000000"
            
        },
      
        text: {
            primary:  "#000000",
            //secondary:"#ffffff",
            
        },
        
        
    },
    typography: {    
        h1: {            
            ////color: 'text.primary',            
            textAlign: 'center',
            fontSize:"5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"5vmax",
            } 
        },
        h2: {            
            ////color: 'text.primary',
            textAlign: 'center',
            fontSize:"4vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"4vmax",
            } 
        },
        h3: {            
            //color: 'text.primary',
            textAlign: 'center',
            fontSize:"3vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"3vmax",
            }  
        },
        h4: {            
            //color: 'text.primary',
            textAlign: 'center',
            fontSize:"2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"2vmax",
            } 
        },
        h5: {            
            //color: 'text.primary',
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            } 
        },
        h6: {            
            //color: 'text.primary',
            textAlign: 'center',
            fontSize:"1.2vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.2vmax",
            } 
        },
        subtitle1: {
            //color:"text.primary",
            textAlign: 'center',
            fontSize:"1.5vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"1.5vmax",
            }  
        },
        subtitle2: {         
            //color:"text.primary",   
            textAlign: 'center',
            fontSize:"0.8vmin",
            fontWeight:"400",
            "@media (max-width:600px)": {
                fontSize:"0.8vmax",
            } 
        },      
        button: {            
            textAlign: 'center',
          
            fontWeight:"400" 
        },
        
        },
    components: {
        MuiTypography: {
            defaultProps: {
            variantMapping: {
                h1: 'h1',
                h2: 'h2',
                h3: 'h3',
                h4: 'h4',
                h5: 'h5',
                h6: 'h6',              
                subtitle1: 'p',
                subtitle2: 'h2',
                body1: 'span',
                body2: 'span',
            },
            },
        },
        MuiButton: {
            defaultProps: {          
                color:"secondary",   
                sx:{
                    fontSize:"1.2vmin",  
                    "@media (max-width:600px)": {
                        fontSize:"1.2vmax",
                    }, 
                    fontWeight:"400"
                }                                                             
            },
            
        },       
        MuiMobileStepper:{
            defaultProps: {          
                color:"text.secondary !important",                   
                sx:{
                    '& .MuiMobileStepper-progress':{
                        color:"text.secondary !important",
                    }
                }
            }
        },
        MuiStepLabel:{
            defaultProps: {                                        
                sx:{                    
                    '& .MuiStepLabel-label.Mui-active':{
                        color:"secondary.dark",
                    },
                    '& .MuiStepLabel-label.Mui-completed':{
                        color:"secondary.main",
                    },
                    
                }
            }
        },
        
        MuiRadio:{
            defaultProps: {          
                color:"info",                    
                sx:{
                    '& .MuiRadio-root':{
                        // color:"#ffffff"
                    },                    
                    '& .Mui-checked':{
                        //color:"text.secondary"
                    }
                
                }
            }
        },
        MuiPagination:{
            defaultProps: {                          
                sx:{
                    
                    '& .MuiButtonBase-root': {
                        borderRadius:"50%",
                        fontSize:"1vmax",
                        width:"2.5vmax",
                        minHeight:"2.5vmax",
                    },
                    '& .MuiSvgIcon-root': {                                                
                        width:"2.5vmax",
                        height:"2.5vmax",
                    },
                }
            }
        },
        MuiBottomNavigation:{
            defaultProps: {                          
                sx:{
                    
                    '& .Mui-selected': {
                        color:"red"                    
                    },
                }
            }
        }
    }, 
});
theme = responsiveFontSizes(theme);

const App = () => {
    const defaultTheme = theme;
  
    const dispatch: AppDispatch = useDispatch()

    const AccToken = useSelector((state: any) => state?.token?.AccToken)
    const User = useSelector((state: any) => state?.token?.user)
    
    const refreshingToken = useSelector((state: any) => state?.token?.refreshingToken)
    
   


    /* Silent Login */
    useEffect(() => {        
        const SilentLogin = async() => {        
           await TokenRefresh()                    
        }
        
        if(!AccToken){
            SilentLogin()
        }        
        return () => {}
    }, [])
    
             
 
    const [info, setInfo] = useState<Iinfo>({} as Iinfo)
    const [editInfo, setEditInfo] = useState<Ieditinfo>({} as Ieditinfo)
    useEffect(() => {                    
        FetchData()            
        return () => {        
        }
    }, [])

    const FetchData = async() => {
        await fetch(`/info/info.json` )
            .then(response => {return response.json()})
            .then(data => {
                setInfo(data)
            })

        await dispatch(GetEditInfo({}))
            .unwrap()
            .then(res => {
                setEditInfo(res?.body?.data)
            })
    }
     
    
    if(!AccToken ) {
        // RefreshToken här ?

        if(refreshingToken) {
            
            return (
                <Stack sx={{ backgroundColor: '#262626', justifyContent:"center", alignItems:"center",height:"100vh" }} spacing={2} direction="row" >
                    <CircularProgress color="primary" />            
                </Stack>
            )        
        }else{
            return (
                <SignIn info={info} />
            )
        }
        
    }else{        
        return (
            
            <Router>

                
                <ThemeProvider theme={defaultTheme}>
                
                        <Navbar User={User}/>

                
                        <Routes>          
                            <Route path="/" element={<Welcomepage info={info} />  } />
                            { UserRoles2?.includes(User?.userRole)  &&  <Route path="/staff" element={<Staff info={info}/>     } /> }
                            { UserRoles2?.includes(User?.userRole)  &&  <Route path="/account" element={<Account info={info} />     } /> }
                            { UserRoles2?.includes(User?.userRole)  &&  <Route path="/info" element={<Info info={info} editInfo={editInfo} />     } /> }
                            { UserRoles2?.includes(User?.userRole)  &&  <Route path="/news" element={<News info={info} />     } />}
                            { UserRoles2?.includes(User?.userRole)  &&  <Route path="/gallery" element={<Gallery info={info} />     } />}
                        </Routes>

                </ThemeProvider>

            </Router>

        )
    }
}

export default App