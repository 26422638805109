import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid"

import { OutputData } from "@editorjs/editorjs";

// mui
import Box from '@mui/material/Box'
import Paper from '@mui/material/Paper'
import Container from '@mui/material/Container'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import TextField from '@mui/material/TextField'
import Select from '@mui/material/Select'
import Typography from "@mui/material/Typography";

/* Data grid imports */
import { GridRenderCellParams, GridColDef } from '@mui/x-data-grid';

// icons
import SaveIcon from '@mui/icons-material/Save'
import EditIcon from '@mui/icons-material/Edit';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';


//redux
import { AddNews, DeleteNews, GetNews } from '../../slices/tokenReducer'
import { AppDispatch } from "../../store";
import { useDispatch, useSelector } from "react-redux";

// self
import { Iimage, Iinfo, Inewsitem } from '../../types/interface';
import { TokenRefresh } from "../../functions/Token"
import { DragDrop, SnackbarComponent, TabsComponent, UpdateTable, UpdateDialogNews, EditorJsComponent, YesNoDialog } from '../../components/index'


interface Iprops {
    info: Iinfo
}

const News = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()
  
    

    const initdata = {
        time: 1635603431943,
        blocks: [
          {
            id: "sheNwCUP5A",
            type: "header",
            data: {
              text: "Editor.js",
              level: 2
            }
          },
          {
            id: "12iM3lqzcm",
            type: "paragraph",
            data: {
              text:
                "Hey. Meet the new Editor. On this page you can see it in action — try to edit this text."
            }
          },
          {
            id: "fvZGuFXHmK",
            type: "header",
            data: {
              text: "Key features",
              level: 3
            }
          },
          {
            id: "xnPuiC9Z8M",
            type: "list",
            data: {
              style: "unordered",
              items: [
                "It is a block-styled editor",
                "It returns clean data output in JSON",
                "Designed to be extendable and pluggable with a simple API"
              ]
            }
          },
          {
            id: "-MhwnSs3Dw",
            type: "header",
            data: {
              text: "What does it mean «block-styled editor»",
              level: 3
            }
          },
          {
            id: "Ptb9oEioJn",
            type: "paragraph",
            data: {
              text:
                'Workspace in classic editors is made of a single contenteditable element, used to create different HTML markups. Editor.js <mark class="cdx-marker">workspace consists of separate Blocks: paragraphs, headings, images, lists, quotes, etc</mark>. Each of them is an independent contenteditable element (or more complex structure) provided by Plugin and united by Editor\'s Core.'
            }
          },
          {
            id: "-J7nt-Ksnw",
            type: "paragraph",
            data: {
              text:
                'There are dozens of <a href="https://github.com/editor-js">ready-to-use Blocks</a> and the <a href="https://editorjs.io/creating-a-block-tool">simple API</a> for creation any Block you need. For example, you can implement Blocks for Tweets, Instagram posts, surveys and polls, CTA-buttons and even games.'
            }
          },
          {
            id: "SzwhuyoFq6",
            type: "header",
            data: {
              text: "What does it mean clean data output",
              level: 3
            }
          },
          {
            id: "x_p-xddPzV",
            type: "paragraph",
            data: {
              text:
                "Classic WYSIWYG-editors produce raw HTML-markup with both content data and content appearance. On the contrary, Editor.js outputs JSON object with data of each Block. You can see an example below"
            }
          },
          {
            id: "6W5e6lkub-",
            type: "paragraph",
            data: {
              text:
                'Given data can be used as you want: render with HTML for <code class="inline-code">Web clients</code>, render natively for <code class="inline-code">mobile apps</code>, create markup for <code class="inline-code">Facebook Instant Articles</code> or <code class="inline-code">Google AMP</code>, generate an <code class="inline-code">audio version</code> and so on.'
            }
          },
          {
            id: "eD2kuEfvgm",
            type: "paragraph",
            data: {
              text:
                "Clean data is useful to sanitize, validate and process on the backend."
            }
          },
          {
            id: "N8bOHTfUCN",
            type: "delimiter",
            data: {}
          },
          {
            id: "IpKh1dMyC6",
            type: "paragraph",
            data: {
              text:
                "We have been working on this project more than three years. Several large media projects help us to test and debug the Editor, to make it's core more stable. At the same time we significantly improved the API. Now, it can be used to create any plugin for any task. Hope you enjoy. 😏"
            }
          }
        ]
    };
    const initdataempty:OutputData = {
        time: Date.now(),
        blocks: []
    }
    const [ data, setData] = useState<OutputData>(initdataempty)
  
    const [ loading, setLoading] = useState<boolean>(false)
    const [ alert, setAlert] = useState<string>("")
    const [ message, setMessage] = useState<string>("")

    const [ reFetch, setReFetch ] = useState<boolean>(false)
    
    const [tab, setTab] = useState(0)
    const [ oldInformation, setOldInformation] = useState<OutputData []>([])
    interface IintroInformation {
        id: string        
        title:string
        intro:string
       
        
    }
    const introInformationinit = {
        id:  `${uuidv4()}` ,
        title:"",
        intro:"",        
    }
    const [ introInformation, setIntroInformation ] = useState<IintroInformation>(introInformationinit)
    const [ thumbnail, setThumbnail ] = useState<Iimage>({} as Iimage)
    const [updateData, setUpdateData] = useState<Inewsitem>({} as Inewsitem)
    const [allNewsItems, setAllNewsItems] = useState<Inewsitem []>([])
    const [updateDialog, setUpdateDialog] = useState(false)
    const [openYesNoDialog,setOpenYesNoDialog] = useState(false)
    const [deletePk,setDeletePk] = useState<string>("")

    
  

    useEffect(() => {
        FetchNewss()
    },[reFetch])
    const FetchNewss = async() => {
        await dispatch(GetNews({}))
            .unwrap()
            .then(res => {
                if(res?.statusCode === 200){
                    const tempArr = res?.body?.data || []               
                    setAllNewsItems( tempArr)
                }
            })
    }

    const ResetAll = () => {
        setData(initdataempty)
        setIntroInformation(introInformationinit)
    }
    const SaveChanges = async() => {
        setLoading(true)
        const res = await TokenRefresh()                                 
        
        if(res !== 200) {setLoading(false) ;return}
        await dispatch(AddNews({newInformation:data, introInformation, newImage: thumbnail }))
            .unwrap()
            .then((res) => {               
               
                if(res?.statusCode === 200){               						                                                           
                    setMessage(info?.News?.Create_Success || "Success")
                    setAlert("success")                    
                    setLoading(false)     
                    ResetAll()                                                                        
                }else{                
                    setMessage(res?.body?.message || info?.News?.Create_Error || "Failed to create")
                    setAlert("error")
                    setLoading(false)
                }
                
            })
    }
    const DeleteApi = async() => {
        setLoading(true)
        const res = await TokenRefresh()                                 
        
        if(res !== 200) {setLoading(false) ;return}
        await dispatch(DeleteNews({pk:deletePk }))
            .unwrap()
            .then((res) => {               
               
                if(res?.statusCode === 200){               			
                    setReFetch(prev => !prev)			                                                           
                    setMessage(info?.News?.Create_Success || "Success")
                    setAlert("success")                    
                    setLoading(false)                                                                             
                }else{                
                    setMessage(res?.body?.message || info?.News?.Delete_Error || "Failed to delete")
                    setAlert("error")
                    setLoading(false)
                }
                setDeletePk("")
            })
    }
    const HandleText = (event:React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> ) => {
        const { name,value } = event?.target
        if(name !== "id"){
            setIntroInformation(prev => ({...prev, [name]:value}))
        }
        
    }

    //*  Open Close  update Dialog         
    const CloseUpdateDialog = () => {
        setUpdateData({} as Inewsitem)  
        setUpdateDialog(false)
    };              
    const OpenUpdateDialog = (params:GridRenderCellParams<Inewsitem>) => {             
      
        setUpdateData(params?.row)        
        setUpdateDialog(true)
    }
    //*  Open Close Delete  Dialog                        
    const OpenDeleteDialog = (params:GridRenderCellParams<Inewsitem>) => {             
                     
        setDeletePk(params?.row?.pk)
        setOpenYesNoDialog(true)
    }
    
    const columns: GridColDef[] = [   
        {
            field: 'updatebtn',
            headerName: '',
            minWidth:150,
            renderCell: (params: GridRenderCellParams<Inewsitem>) => (                      
                <Button variant="contained" color='primary' endIcon={<EditIcon />} sx={{p:"0 0.5rem"}} onClick={()=>OpenUpdateDialog(params)}>
                    {info?.News?.Update_Button}
                </Button>
            ),
        },    
        {
            field: 'deletebtn',
            headerName: '',
            minWidth:150,
            renderCell: (params: GridRenderCellParams<Inewsitem>) => (                               
                 <Button variant="outlined" endIcon={<DeleteForeverIcon />} sx={{padding:"0 0.5rem !important"}} onClick={()=>OpenDeleteDialog(params)} color='error'>
                    {info?.News?.Delete_Button}
                </Button>
            ),
        },        
        {   
            field: 'title',
            headerName: 'Title',
            minWidth: 200,
            editable: false,     
            flex:1,                   
        }                                                    
    ];


 

    return (
        <Box sx={{width:"100vw",minHeight:"200vh", justifyContent:"flex-start"}} className="flex__center-c">

            <TabsComponent setTab={setTab} tab={tab} labels={[info?.News?.Tab_Create,/* info?.News?.Tab_Preview, */info?.News?.Tab_All]} info={info}/>


            {/* Create News */}
            {tab === 0 && 
            <Box  sx={{width:"100%"}}>
                <Container                                
                    sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
                >   
                    {/* Save Button */}
                    <Button
                        sx={{minWidth: '180px'}}
                        variant="contained"
                        color='success'
                        disabled={loading}
                        onClick={SaveChanges}
                        endIcon={<SaveIcon />}
                    >
                    {info?.News?.Button_Save}
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: "teal",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </Button>

                    {/* Reset Button */}
                    <Button variant="outlined" color="warning" sx={{minWidth: '180px'}} onClick={ResetAll}>
                        {info?.News?.Button_Reset}
                    </Button>
                           
                </Container>

                <Paper sx={{width:"100%",backgroundColor:"#F5F5DC"}} >

                    <Box className="flex__center-c" sx={{m:"2rem 0",borderBottom:"1px solid black"}}>
                        <Typography variant="h3" sx={{m:"1rem 0"}}>{info?.News?.Title_CreateNews}</Typography>
                        <TextField onChange={HandleText} value={introInformation?.title} name="title" label="Title" sx={{width:"90%",mb:"1rem"}}></TextField>
                        <TextField onChange={HandleText} value={introInformation?.intro} name="intro" label="Intro Text" sx={{width:"90%",mb:"1rem"}} multiline></TextField>
                  {/*       <Typography variant="h4" sx={{mt:"1rem"}}>{info?.News?.CategoryLabel}</Typography> */}
              
                        <Typography variant="h4" sx={{mt:"1rem"}}>{info?.News?.DragDropLabel}</Typography>
                        <DragDrop setMessage={setMessage} setAlert={setAlert} setImage={setThumbnail} info={info} image={ thumbnail } id={ introInformation?.id }
                            category={"news"}
                            width={500}
                            height={500} 
                            type={"WEBP"} 
                            quality={80} 
                        />
                    </Box>

                    
                            
                    <EditorJsComponent
                        id={introInformation?.id}
                        readOnly={false}
                        data={data}
                        setData={setData}
                        info={info}
                        category={"news"}
                    />

                </Paper>
            </Box>}

            {/* Preview News */}
            {tab === 2 && 
            <Box  sx={{width:"100%"}}>
                 <EditorJsComponent
                        id={introInformation?.id}
                        readOnly={true}
                        data={data}
                        setData={setData}
                        info={info}
                        category={"news"}
                    />

            </Box>}


            {/* Update News */}
            {tab === 1 && 
            <Box  sx={{width:"100%"}}>

                <UpdateTable 
                    info={info}
                    rows={allNewsItems}                
                    columns={columns}
                    rowSelectionToogle={false}
                />

                <UpdateDialogNews                                      
                    updateDialog={updateDialog}
                    CloseUpdateDialog={CloseUpdateDialog}
                    row={updateData}                    
                    setReFetch={setReFetch}
                    info={info}
                   
                /> 

                <YesNoDialog 
                    submitFunc={DeleteApi}
                    openYesNoDialog={openYesNoDialog}
                    setOpenYesNoDialog={setOpenYesNoDialog}
                    info={info}
                />

            </Box>}

            <SnackbarComponent alert={alert} setAlert={setAlert} setMessage={setMessage} message={message} />

        </Box>
    );
}

export default News



const jsontest = [
    {
        "id": "2Zu26_Mz6O",
        "type": "code",
        "data": {
            "code": "const test = \"123\""
        }
    },
    {
        "id": "-QWii5HVCh",
        "type": "list",
        "data": {
            "style": "ordered",
            "items": [
                "test",
                "test",
                "tst3"
            ]
        }
    },
    {
        "id": "jtkeXi13oZ",
        "type": "list",
        "data": {
            "style": "ordered",
            "items": []
        }
    },
    {
        "id": "9IwPnbJY2p",
        "type": "header",
        "data": {
            "text": "WOW",
            "level": 2
        }
    },
    {
        "id": "dw9zd5H6Au",
        "type": "raw",
        "data": {
            "html": "<h1>test213</h1>"
        }
    },
    {
        "id": "z8CwQzpl3n",
        "type": "image",
        "data": {
            "file": {
                "url": "https://cdn.heineras.se/news/testid.webp"
            },
            "caption": "test",
            "withBorder": false,
            "stretched": false,
            "withBackground": true
        }
    },
    {
        "id": "Xvo7bSlWGv",
        "type": "header",
        "data": {
            "text": "heading",
            "level": 2
        }
    },
    {
        "id": "mM-JWfRCRK",
        "type": "quote",
        "data": {
            "text": "quote",
            "caption": "quote caption",
            "alignment": "left"
        }
    },
    {
        "id": "F761egIPob",
        "type": "checklist",
        "data": {
            "items": [
                {
                    "text": "checklist",
                    "checked": true
                },
                {
                    "text": "checlist2",
                    "checked": false
                }
            ]
        }
    },
    {
        "id": "OvXFdqt6OT",
        "type": "delimiter",
        "data": {}
    },
    {
        "id": "AmYx1p5rhC",
        "type": "table",
        "data": {
            "withHeadings": false,
            "content": [
                [
                    "table",
                    "1"
                ],
                [
                    "teable2",
                    "2"
                ]
            ]
        }
    },
    {
        "id": "t_pMDHskwP",
        "type": "warning",
        "data": {
            "title": "warning",
            "message": ""
        }
    }
]