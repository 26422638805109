import { useEffect, Fragment, useState, forwardRef , useMemo} from 'react';
import Resizer from "react-image-file-resizer";
import { useDropzone } from 'react-dropzone'
import { v4 as uuidv4 } from 'uuid'

// Mui
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Unstable_Grid2';
import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import DeleteIcon from '@mui/icons-material/Delete';
import Button from '@mui/material/Button';
import { alpha } from '@mui/material/styles';


import CircularProgress from '@mui/material/CircularProgress';

//Mui icons
import ClearIcon from '@mui/icons-material/Clear';
import SaveIcon from '@mui/icons-material/Save';

// REDUX
import { useDispatch } from 'react-redux'
import { UpdateGallery, GetGallery } from '../../slices/tokenReducer'; 
import { AppDispatch } from '../../store'
import { TokenRefresh } from '../../functions/Token';
import { Iinfo } from '../../types/interface';
import { SnackbarComponent } from '../../components';

/* Dropzone Style */
const dropzoneStyle = {
    baseStyle: {
        flex: 1,
        display: 'flex',
        FlexDirection: 'column',
        alignItems: 'center',
        padding: '20px',
        borderWidth: 2,
        borderRadius: 2,
        borderColor: '#eeeeee',
        borderStyle: 'dashed',
        backgroundColor: '#fafafa',
        color: '#bdbdbd',
        outline: 'none',
        transition: 'border .24s ease-in-out'
    },
    focusedStyle: {
        borderColor: '#2196f3'
    },
    acceptStyle: {
        borderColor: '#00e676'
    },
    rejectStyle: {
        borderColor: '#ff1744'
    }

}
interface fileinterface {          
    file: string                        
    key: string    
    type: string
    
}
interface Iprops {
    info: Iinfo | undefined
}
const Gallery = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()

   
    const [selected, setSelected] = useState<string []>([]);
    const [message, setMessage] = useState<string | undefined>("")
    const [loading, setLoading] = useState(false)
    
    const [alert,setAlert] = useState("")
    const [newItems, setNewItems] = useState<fileinterface []>([])
    const [visibleItems, setVisibleItems] = useState<fileinterface []>([])
    const [removedItems, setRemovedItems] = useState<string []>([])
    const [updatedGalleryKeys, setUpdatedGalleryKeys] = useState<string []>([])
    const [oldItems, setOldItems] = useState<fileinterface []>([])
    
  

    const ChangeFormatOfVisibleItems = (items: string[]) => {
      
        const returnItems = []
        for (let i = 0; i < items.length; i++) {
            const item = items[i];

            returnItems.push({
                key: item,
                file:"",
                type: ""
            })
            
        }
        return returnItems
    }

    //* Fetch all images
    useEffect(() => {       

        FetchGallery()
        return () => {
        
        }
    }, [])


    const FetchGallery = async() => {
        await dispatch(GetGallery({}))
                .unwrap()
                .then(res => {
                   
                    const fetchdata: string [] = res?.body?.data?.Gallery
                    const correctFormat = ChangeFormatOfVisibleItems(fetchdata)
                    setVisibleItems(correctFormat)
                    setOldItems(correctFormat)
                    setUpdatedGalleryKeys(fetchdata)
            })    
    }
    
    
    const isSelected = (name: string) => selected.indexOf(name) !== -1;



    //* Resize and covert image to webp via drag and drop
    const resizeFile = (file:any) =>
    new Promise((resolve) => {
    Resizer.imageFileResizer(
        file,
        4080,            // max width
        4080,            //max height
        "WEBP",         // file output
        100,             // 100 best quality, 50, middle, 1 lowest quality
        0,
        (uri) => {
        resolve(uri);
        },
        "base64"
    );
    });
    const onDrop = async (acceptedFiles:any) => {      
        try {          

            for (let i = 0; i < acceptedFiles.length; i++) {
                const file = acceptedFiles[i];
                const compressedImage:any = await resizeFile(file);                     
                let newFile = {
                    key: `gallery/${uuidv4()}.webp`,
                    file: compressedImage,
                    type: "image/webp"
                }
                
                setNewItems(prev=>[...prev, newFile ])
                setVisibleItems((prev) => [...prev, newFile ])
                setUpdatedGalleryKeys((prev) => [...prev,newFile?.key])
                
            }
                  
            
            
        } catch (error) {           
            setMessage(info?.Gallery?.FileError)
            setAlert("error")                
        }  
    }
    const {
        getRootProps,
        getInputProps,
        isFocused,
        isDragAccept,
        isDragReject
    } = useDropzone({onDrop, accept: {'image/*': []}});
    const style = useMemo(() => ({
        ...dropzoneStyle?.baseStyle,
        ...(isFocused ? dropzoneStyle?.focusedStyle : {}),
        ...(isDragAccept ? dropzoneStyle?.acceptStyle : {}),
        ...(isDragReject ? dropzoneStyle?.rejectStyle : {})
    }), [
        isFocused,
        isDragAccept,
        isDragReject
    ]);
    


    //* Remove selected images
    const RemoveItems = ( ) => {
        let tempRemovedItems = [...removedItems]
        let selectedId = [...selected]
        let tempRows = [...visibleItems]
        let tempKeys = [...updatedGalleryKeys]
        let tempNewItems = [...newItems]

        
        
        for (let i = 0; i < selectedId.length; i++) {
            const idToBeRemoved = selectedId[i];

            for (let j = 0; j < tempRows.length; j++) {
                const rowId = tempRows[j];
                if(idToBeRemoved === rowId?.key) {

                    const foundInNewItemsIndex = tempNewItems?.findIndex(item => item.key === idToBeRemoved)
                    let removedItem = tempKeys.splice(j,1
                        )
                    if( foundInNewItemsIndex !== -1 ){
                        tempNewItems.splice(foundInNewItemsIndex,1)
                    }else{                                                                        
                        tempRemovedItems.push(removedItem[0])
                    }
                    tempRows.splice(j,1)                    

                    
                }    
            }
                              
        }
        
        setRemovedItems([...tempRemovedItems])
        setUpdatedGalleryKeys([...tempKeys])
        setNewItems([...tempNewItems])
        setSelected([])
       
        setVisibleItems([...tempRows])
    
    }    
    const ResetAll = ( ) => {     
        setVisibleItems(oldItems)
        setSelected([])
        setRemovedItems([])
        setUpdatedGalleryKeys([])
        setNewItems([])
    }
    //* API PUT CALL => Save images
    const SaveChanges = async() => {
        setMessage("")
        if(newItems.length <= 0 && removedItems.length <= 0){
            setMessage(info?.Gallery?.SnackbarNoChangesMade)
            setAlert("warning")
            setLoading(false)
            return
        }

        if(newItems.length > 10){
            setMessage(info?.Gallery?.SnackbarErrorMaxImages)
            setAlert("warning")
            setLoading(false)
            return
        }
        setLoading(true)
        const DispatchItem = async (index:number,updatedItems:any) => {

            if(index >= 10) return { statusCode: 400 }
            
            var returndata              
            let dispatchData
            if(index === 0){
                dispatchData = {    
                    newImage: newItems?.[index]?.file,
                    newImageType: newItems?.[index]?.type,
                    //createdEvent: createDataNoImage,
                    newImageKey: newItems?.[index]?.key,
                    updatedGallery: updatedGalleryKeys,
                    removedImages: removedItems,
                    index: index                 
                }
            }else{
                dispatchData = { 
                    newImage: newItems?.[index]?.file,
                    newImageType: newItems?.[index]?.type,                
                    newImageKey: newItems?.[index]?.key,                    
                    index: index   
                }
            }


            await dispatch(UpdateGallery(dispatchData))
            .unwrap()
            .then((res:any) => {                                                  
                returndata = { statusCode: res?.statusCode, message: res?.body?.message }
            })    
            return returndata
        }
     

        let data = {oldItems ,
        newItems,
        removedItems}
		

		let inputData:any = []
		let lastCall = newItems.length
        let newItemsOrRemovedItemsLength = newItems.length !== 0 ? newItems.length : removedItems.length
        
        const res = await TokenRefresh()                                         
        if(res !== 200) {setLoading(false) ;return}

		for(let i = 0; i< newItemsOrRemovedItemsLength; i++){        
			const returnData:any = await DispatchItem(i, inputData)                
            if(returnData?.statusCode !== 200) {
                setMessage(returnData?.message || "Unknow Error")
                setAlert("error")
                setLoading(false)
                return
            }
			inputData = returnData
		}
		if(inputData?.statusCode === 200){
            setTimeout(() => {
                setMessage(info?.Gallery?.SnackbarSuccessUploaded)
                setAlert("success")
                setLoading(false)
                FetchGallery()
            },2000)
		}
		
    }
    //* Select one or more images to be removed via RemoveItems()
    const SelectImage = (event: React.MouseEvent<unknown>, name: string) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected: string[] = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
            selected.slice(0, selectedIndex),
            selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    }




    //* Snackbar Alert  */
 




    //? Components
  
    //* Toolbar with main title, delete, filter etc  */
    const EnhancedTableToolbar = (numSelected: number) =>{
        
        return (
        <Toolbar
        
            sx={{
            pl: { sm: 2 },
            pr: { xs: 1, sm: 1 },
            ...(numSelected > 0 && {
                bgcolor: (theme) =>
                alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
            }),
            }}
        >
            {numSelected > 0 ? (
            <Typography
                sx={{ flex: '1 1 100%' }}
                color="inherit"
                variant="subtitle1"
                component="div"
            >
                {numSelected} {info?.Gallery?.Selected}
            </Typography>
            ) : (
            <Typography
                sx={{ flex: '1 1 100%' }}
                variant="h6"
                id="tableTitle"
                component="div"
            >
                {info?.Gallery?.MainTitle}                              
                    
            </Typography>
            )}
            {numSelected > 0 ? (
                <Box sx={{display:"flex"}}>
                    <Tooltip title={info?.Gallery?.ToolTip_ClearDelete}>
                        <IconButton  onClick={()=>setSelected([])}>
                            <ClearIcon/>
                        </IconButton>
                    </Tooltip>                
                    <Tooltip title={info?.Gallery?.DeleteTooltip}>
                        <IconButton  onClick={RemoveItems}>
                            <DeleteIcon/>
                        </IconButton>
                    </Tooltip>
                </Box>
            ) : (    <Box sx={{display:"flex"}}>
              
             
            </Box>
            )}
        </Toolbar>
        );
    }

    //* All Images  */
    const GalleryTable = () => {

        //* Checks if the image is an old http link or a new base64 string and returns correct format for <img> */
        const CheckImg = (img: fileinterface,srcset:number) => {       
                                
            // old image
            if(!img?.file) {
                if(srcset === 1){
                    return  `https://${process.env.REACT_APP_CDN_URL}/${img?.key}`
                }else if(srcset === 2){
                    return `https://${process.env.REACT_APP_CDN_URL}/${img?.key}`
                }

            // new image
            }else{
                //let base64String = img?.file?.split(",")[1]
                return img?.file
               /*  let res = base64String.search(/^(?:[A-Za-z0-9+/]{4})*(?:[A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=|[A-Za-z0-9+/]{4})$/)
                if(res !== -1){
                    return img?.file
                }else if(srcset === 1){
                    return `${img?.file}?w=164&h=164&fit=crop&auto=format`
                }else if(srcset === 2){
                    return `${img?.file}?w=164&h=164&fit=crop&auto=format&dpr=2 2x`
                } */
            }    
            return              
           /*  
             */

        }
       
        return (
            <Box sx={{ flexGrow: 1, p: 2 }}>
                <Grid
                container
                spacing={2}
                sx={{
                '--Grid-borderWidth': '1px',
                borderTop: 'var(--Grid-borderWidth) solid',
                borderLeft: 'var(--Grid-borderWidth) solid',
                borderColor: 'divider',
                '& > div': {
                    borderRight: 'var(--Grid-borderWidth) solid',
                    borderBottom: 'var(--Grid-borderWidth) solid',
                    borderColor: 'divider',
                },
                }}
            >
                {visibleItems.map((item) => {
                    const isItemSelected = isSelected(item?.key)
                   
                     return(
                    
                    <ImageListItem key={`galleryImg${item?.key}`}>             
                         <ImageListItemBar
                                sx={{
                                    background:
                                    'linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, ' +
                                    'rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)',
                                }}
                             /*    title={item} */
                                position="top"
                                actionIcon={
                                    <IconButton
                                    sx={{ color: 'white' }}
                                    aria-label={`star ${item}`}
                                     onClick={(event) => SelectImage(event, item?.key)}
                                    >
                                    <Checkbox
                                        checked={isItemSelected}
                                       
                                        color="primary"
                                        sx={{ cursor: 'pointer',color: "#ffffff",
                                        '&.Mui-checked': {
                                          color: "#ffffff",
                                        }, }}
                                    
                                        inputProps={{
                                        'aria-labelledby': "labelId",
                                        }}
                                    />
                                    </IconButton>
                                }
                                actionPosition="left"
                                />
                        <img
                            src={CheckImg(item,1)}
                            srcSet={CheckImg(item,2)}
                            style={{ width: '164px', height: '164px', objectFit:'cover'}}
                            alt={item?.key}
                            loading="lazy"
                        />
                    </ImageListItem>
                )})}
            </Grid>
          </Box>
        );
    }
    

    return (
        <Fragment>
            <CssBaseline />
            <Container maxWidth="xl" sx={{padding:"0 !Important"}}>     

                {/*  Save, dropzone and Reset button */}
                <Container                                
                    sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
                >
                    <Button
                        sx={{width: '180px'}}
                        variant="contained"
                        color='success'
                        disabled={loading}
                        onClick={SaveChanges}
                        endIcon={<SaveIcon />}
                    >
                    {info?.Gallery?.Save}
                    {loading && (
                        <CircularProgress
                            size={24}
                            sx={{
                            color: "teal",
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            marginTop: '-12px',
                            marginLeft: '-12px',
                            }}
                        />
                    )}
                    </Button>

                    <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={ResetAll}>
                        {info?.Gallery?.Reset}
                    </Button>

                    {/* DropZone */}
                    <div className="container">
                        <div {...getRootProps({style})}>
                            <input {...getInputProps()} />
                            <p>{info?.Gallery?.DropAndDrag}</p>
                        </div>
                    </div>
                
                </Container>           

                {/* Toolbar */}
                {EnhancedTableToolbar(selected.length)}

                {/* All Images */}
                {GalleryTable()}


              <SnackbarComponent alert={alert} setAlert={setAlert} message={message} setMessage={setMessage}/>
                



            </Container>
        </Fragment>   
    )
}

export default Gallery



