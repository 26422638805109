import { useEffect, useState } from 'react';

// mui
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';

import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';

//redux
import  { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'

// self
import { UpdateStaff } from '../../slices/tokenReducer'; 
import { TokenRefresh } from '../../functions/Token';
import { SnackbarComponent, DragDrop } from '../../components/index'
import { Iinfo, Iimage, Istaff } from '../../types/interface';


const categories = [    
    "crossfit",
    "bodybuilding",
    "cardio",
    "kost",
    "övrigt",    
]
interface Iupdatedialog {             
    updateDialog: boolean
    CloseUpdateDialog: () => void
    row: Istaff
    rows: Istaff []
    info: Iinfo
    setTriggerFetchData: (p:React.SetStateAction<boolean>) => void
}
const UpdateDialogImage = (props:Iupdatedialog) => {
    const { updateDialog, CloseUpdateDialog, row,rows, info, setTriggerFetchData } = props;

    const dispatch: AppDispatch = useDispatch()
    
    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    const [loadingUpdate, setLoadingUpdate] = useState(false)
    const [ updateRow, setUpdateRow ] = useState(row)
    const [ updateImage, setUpdateImage ] = useState<Iimage>({key: updateRow?.image, file:"",type:""})
    
   
               
    useEffect(() => {
        setUpdateRow(row)         
        setUpdateImage({key: row?.image, file:"",type:""})   
        return () => {}
    }, [row])

    const HandleTextFields = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event?.target
        setUpdateRow(prev => 
            ({
                ...prev, [name]: value
            }))
    }             
    const UpdateStaffAPI = async() => {
        try{
            setLoadingUpdate(true)                      
            const tokenres = await TokenRefresh()          
            if(tokenres !== 200) {           
                setLoadingUpdate(false)
                return
            }        
         
            await dispatch(UpdateStaff({                 
                updatedItem:updateRow,
                oldItems: rows,                
                updatedImage: updateImage,                    
            }))
                .unwrap()
                .then(res => {
                    if(res?.statusCode === 200 ){
                        setTimeout(() => {                            
                            setAlert("success")
                            setMessage(info?.Staff?.Snackbar_Success_Update)
                            setLoadingUpdate(false)
                            setTriggerFetchData(prev => !prev)
                        }, 2000); 
                        setTimeout(() => {                            
                            CloseUpdateDialog()
                            setAlert("")
                            setMessage("")
                        }, 3000); 
                      
                    }else{   
                        setLoadingUpdate(false)
                        setAlert("error")
                        setMessage( info?.Staff?.Snackbar_Error)                                                                                                    
                    }                        
                })                                                                                        
        }
        catch (error) {
            setAlert("error")
            setMessage(info?.Staff?.Snackbar_Error)
            setLoadingUpdate(false)
    
        }
      
    }

    
    return (                       
        <Dialog
          open={updateDialog}
          onClose={CloseUpdateDialog}
       
        >         
            <DialogTitle className="flex__center-r" sx={{justifyContent:"space-around"}}>

                <Typography variant="subtitle1" >
                    {info?.Staff?.Update_Title}
                </Typography>

                <IconButton
                    edge="start"
                    color="inherit"
                    onClick={CloseUpdateDialog}
                    aria-label="close"
                >
                    <CloseIcon />
                </IconButton>

            </DialogTitle>


            <DialogContent>                
                <DialogActions >                                             
                    <Box className="flex__center-c" >
                  

                        {/* Submit Button */}
                        <Button
                            sx={{width: '180px'}}
                            variant="contained"
                            color='success'
                            type='submit'
                            disabled={loadingUpdate}
                            onClick={UpdateStaffAPI}
                            endIcon={<SaveIcon />}
                        >
                            {info?.Staff?.Update_Button}
                            {loadingUpdate && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "white",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                        )}
                        </Button>

                        <TextField       
                            required                         
                            label={`Title`}
                            sx={{m:"0.5rem 0",label:{color:"grey.400"}}}                                                                                 
                            multiline
                            fullWidth 
                            maxRows={4}
                            name={`title`}
                            value={updateRow?.title}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                HandleTextFields(event);
                            }}
                        />
                          <TextField    
                            required                          
                            label={`info`}
                            sx={{m:"0.5rem 0",label:{color:"grey.400"}}}
                            multiline
                            fullWidth                             
                            name={`info`}
                            value={updateRow?.info}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                HandleTextFields(event);
                            }}
                        />
                        <TextField    
                            required                          
                            label={`Info`}
                            sx={{m:"0.5rem 0",label:{color:"grey.400"}}}
                            multiline
                            fullWidth                             
                            name={`info`}
                            value={updateRow?.info}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                HandleTextFields(event);
                            }}
                        />

                     {/*    <Autocomplete                            
                            value={updateRow?.category}
                            size='small'
                            disablePortal                                
                            options={categories}
                            sx={{ width: 200 }}
                            renderInput={(params) => <TextField {...params}  label="Kategori" />}
                            onChange={(event,value)=> value && setUpdateRow(prev => ({...prev, category: value}))}                             
                        /> */}
                                                                          
                        <DragDrop setMessage={setMessage} setAlert={setAlert} setImage={setUpdateImage} info={info} image={ updateImage } id={updateRow?.id}
                            category={"staff"}
                            width={1080}
                            height={1080} 
                            type={"WEBP"} 
                            quality={80} 
                        />
                                                                                       
                    </Box>                    
                </DialogActions>
            </DialogContent>    


            {/* Alertbars / Snackbars  */}
            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />

          
        </Dialog>
    )
} 

export default UpdateDialogImage