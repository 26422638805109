import {jwtDecode} from 'jwt-decode'
import { RefreshToken } from '../slices/tokenReducer'


import store from '../store'

interface decodedAccTokenInit  {
    exp: number
    iat: number
    userRole: string
    tokenId: string
    userId: string
}

export const TokenRefresh = async() => {

    try {
        let status:string = store.getState().token.status;    
       
        if(status === "loading") return 429
        let AccToken:string = store.getState().token.AccToken;        
 
        

        const DispatchRefreshToken = async() => {
        
            let statusCode
            await store.dispatch(RefreshToken({}))
            .unwrap()
            .then((res) => {            
         
                
                statusCode = res?.statusCode
                
                
            })
            .catch((error) => {
                                                    
                return 500
            })
            return statusCode
        }
        
        
     
        if(!AccToken){
        
            return await DispatchRefreshToken()
        }

        let decodedAccToken:decodedAccTokenInit = jwtDecode(AccToken);    
        
        if(decodedAccToken?.exp < Date.now() / 1000 || !AccToken){               
            
            return await DispatchRefreshToken()              
        }else{        
            
            return 200
        }        

    } catch (error) {
               
        return 500
    }

}

