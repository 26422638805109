import { useState, ChangeEvent, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import Container from '@mui/material/Container';
import Button from '@mui/material/Button';
import CircularProgress from '@mui/material/CircularProgress';
import SaveIcon from '@mui/icons-material/Save';

// Redux
import  { AppDispatch }  from '../../store'
import { useDispatch } from 'react-redux'
import { UpdateInfo } from '../../slices/tokenReducer'

// Self
import { TokenRefresh } from '../../functions/Token';
import { Ieditinfo, Iinfo, Iinfodb } from '../../types/interface';
import { SnackbarComponent } from '../index';
import { Divider } from '@mui/material';







interface Iprops {
    info: Iinfo | undefined
    editInfo: Ieditinfo
    infoFromDb:Iinfodb | undefined
}
//* MAIN TABLE */
const InfoTable = (props:Iprops) =>{        

    const { info, editInfo, infoFromDb } = props

    const dispatch: AppDispatch = useDispatch()
     
    const [message, setMessage] = useState("")
    const [alert, setAlert] = useState("")
    const [loading, setLoading] = useState(false)
    const [jsonInfo, setJsonInfo] = useState<Ieditinfo>(editInfo)
    const [infoDb, setDbInfo] = useState<Iinfodb>(infoFromDb as Iinfodb)

   
    
    //* Updates states when rendering first time or change of props  */
    useEffect(() => {
        setJsonInfo(editInfo)
        setDbInfo(infoFromDb as Iinfodb)
      return () => {
        
      }
    }, [editInfo, infoFromDb])


    //* Changes value of string and number */
    const ChangeValue = (event:ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,firstKey: string, secondKey: string )=> {
        const { name, value } = event.target;            

        const firstIndex = firstKey as keyof Ieditinfo

        setJsonInfo((prev) => ({
            ...prev,
            [firstKey]:{
                ...prev?.[firstIndex],
                [secondKey]: value  
            }
        }))

        
        
        
        
    }


    //* Reset All changes  */
    const ResetAll = () => {
        setJsonInfo(editInfo)
        setDbInfo(infoFromDb as Iinfodb)
    }
    //* Save Changes at Server  */
    const SaveChanges = async () => {   
        setMessage("")    

        if(loading){
           return 
        }             

        setLoading(true)

        const tokenres = await TokenRefresh() 
        if(tokenres !== 200) {
            setLoading(false)
            return
        }

        await dispatch(UpdateInfo({ information: jsonInfo, dbInformation: infoDb }))
        .unwrap()
            .then((res) => {                                                  
                if(res?.statusCode === 200){               						                                   
                    setMessage("Success!")
                    setAlert("success")
                    setLoading(false)
                                                                
                }else{                
                    setMessage(res?.body?.message || "Unknow Error")
                    setAlert("error")
                    setLoading(false)
                }
            })        
                   
        
    }

    const ChangeArray = (name:string, index:number,value:string) => {
        
        setJsonInfo(prev => ({
            ...prev,
            Qa:{
                ...prev?.Qa,
                Pricing: prev?.Qa?.Pricing.map((item,i) => i ===index ? ({...item, [name]:value}) : item )
            }                                    
        }))    
    }
    const AddNewElementPricing = () => {
        const newitem = {
            "title": "",
            "price_young": "",
            "price_adult": "",
            "price_senior": "",
        }
        setJsonInfo(prev => ({
            ...prev,
            Qa:{
                ...prev?.Qa,
                Pricing: [newitem,...prev?.Qa?.Pricing]
            }                                    
        })) 
    }
    const RemoveElementPricing = (index:number) => {
      
        setJsonInfo(prev => ({
            ...prev,
            Qa:{
                ...prev?.Qa,
                Pricing: prev?.Qa?.Pricing.filter((item,i) => i !== index )
            }                                    
        })) 
    }
    
  return (
    <Box maxWidth="lg" className="flex__center-c">

        <Container                                
            sx={{ display:"flex", alignItems:"center", width: '100% !important', padding:"1rem 0", flexDirection: 'column', gap:'0.5rem'}}
        >
            <Button
                sx={{width: '180px'}}
                variant="contained"
                color='success'
                disabled={loading}
                onClick={SaveChanges}
                endIcon={<SaveIcon />}
            >
            {info?.Info?.Save}
            {loading && (
                <CircularProgress
                    size={24}
                    sx={{
                    color: "teal",
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    marginTop: '-12px',
                    marginLeft: '-12px',
                    }}
                />
            )}
            </Button>

            <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={ResetAll}>
                {info?.Info?.Reset}
            </Button>
            
        </Container>


        <Stack spacing={5} >


            <Typography variant='h3'>
                {info?.Info?.Title_GeneralInfo}
            </Typography>
            <Box>
            {Object.keys(jsonInfo?.Footer || {}).map((FooterKey:string,i) => {
                return(
                <TextField fullWidth size='small' multiline sx={{m:"0.5rem 0",label: {color:"grey.400"}}} key={`info_footer${FooterKey}${i}`}
                    name={FooterKey}
                    label={FooterKey}
                    value={ jsonInfo?.Footer?.[FooterKey as keyof Ieditinfo["Footer"]]  || ""}
                    onChange={(event)=>ChangeValue(event, "Footer", FooterKey )}
                /> 
            )})}
            </Box>

            <Divider />
            
            <Typography variant='h3'>                
                {info?.Info?.Title_Qa}
            </Typography>
            <TextField fullWidth size='small' multiline sx={{label: {color:"grey.400"}}} 
                    name={"OpenTime_Full"}
                    label={"Opentime for members"}
                    value={ jsonInfo?.Qa?.OpenTime_Full || ""}
                    onChange={(event)=>ChangeValue(event, "Qa", "OpenTime_Full" )}
            /> 
             <TextField fullWidth size='small' multiline sx={{label: {color:"grey.400"}}} 
                    name={"OpenTime_Staff"}
                    label={"Opentime when gym is staffed"}
                    value={ jsonInfo?.Qa?.OpenTime_Staff || ""}
                    onChange={(event)=>ChangeValue(event, "Qa", "OpenTime_Staff" )}
            /> 
            <TextField fullWidth size='small' multiline sx={{label: {color:"grey.400"}}} 
                    name={"Questions"}
                    label={"Questions"}
                    value={ jsonInfo?.Qa?.Questions || ""}
                    onChange={(event)=>ChangeValue(event, "Qa", "Questions" )}
            /> 

            <Divider />
            
            <Typography variant='h3'>
                {info?.Info?.Title_Price}                
            </Typography>
            <Button onClick={AddNewElementPricing} sx={{maxWidth:"15rem"}} variant='outlined'>
                {info?.Info?.Button_AddPrice}
            </Button>
            {jsonInfo?.Qa?.Pricing.map((item,i) => {
                
                return(
                    <Box key={`pricingcard${item}${i}`}>
                        <Button onClick={()=>RemoveElementPricing(i)} variant='outlined'>
                            {info?.Info?.Button_RemovePrice}                            
                        </Button>
                        
                        
                        <TextField fullWidth size='small' multiline sx={{m:"0.5rem 0",label: {color:"grey.400"}}}  key={`title${i}`}
                            name={"title"}
                            label={"title"}
                            value={ item?.title || ""}
                            onChange={(event)=>ChangeArray(event?.target?.name, i, event?.target.value )}
                        /> 
                        <TextField fullWidth size='small' multiline sx={{m:"0.5rem 0",label: {color:"grey.400"}}} key={`young${i}`}
                            name={"price_young"}
                            label={"price_young"}
                            value={ jsonInfo?.Qa?.Pricing?.[i]?.["price_young" as keyof typeof jsonInfo.Qa.Pricing[typeof i] ] || ""}
                            onChange={(event)=>ChangeArray(event?.target?.name, i, event?.target.value )}
                        /> 
                        <TextField fullWidth size='small' multiline sx={{m:"0.5rem 0",label: {color:"grey.400"}}}  key={`adult${i}`}
                            name={"price_adult"}
                            label={"price_adult"}
                            value={ jsonInfo?.Qa?.Pricing?.[i]?.["price_adult" as keyof typeof jsonInfo.Qa.Pricing[typeof i] ] || ""}
                            onChange={(event)=>ChangeArray(event?.target?.name, i, event?.target.value )}
                        /> 
                        <TextField fullWidth size='small' multiline sx={{m:"0.5rem 0",label: {color:"grey.400"}}} key={`senior${i}`}
                            name={"price_senior"}
                            label={"price_senior"}
                            value={ jsonInfo?.Qa?.Pricing?.[i]?.["price_senior" as keyof typeof jsonInfo.Qa.Pricing[typeof i] ] || ""}
                            onChange={(event)=>ChangeArray(event?.target?.name, i, event?.target.value )}
                        /> 
                        
                        
                    
                    </Box>
                )
            })}



      

                        
            {/* Db Info */}
            {infoDb && 
            <Stack spacing={5} maxWidth="md">


             

            


            </Stack>
            }

        </Stack>
        
        <SnackbarComponent alert={alert} setAlert={setAlert} setMessage={setMessage} message={message} />


    </Box>
  );
}

export default InfoTable