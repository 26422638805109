import { useEffect, useState } from 'react';
import { v4 as uuidv4 } from 'uuid'

// mui
import { alpha } from '@mui/material/styles';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import CssBaseline from '@mui/material/CssBaseline';
import Container from '@mui/material/Container';
import Paper from '@mui/material/Paper';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';
import Toolbar from '@mui/material/Toolbar';

/* Data grid imports */
import { GridRenderCellParams, DataGrid, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';


// mui icons
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';

//redux
import  { AppDispatch } from '../../store'
import { useDispatch } from 'react-redux'

// self
import { DeleteStaff, UpdateStaff, GetStaff } from '../../slices/tokenReducer'; 
import { TokenRefresh } from '../../functions/Token';
import { SnackbarComponent,SimpleBackdropLoading, DragDrop, UpdateDialogImage } from '../../components/index'
import { Iinfo, Iimage, Istaff } from '../../types/interface';


const categories = ["pt","staff"]

interface Iprops {
    info: Iinfo
}



const Staff = (props:Iprops) => {
    const { info } = props

    const dispatch: AppDispatch = useDispatch()
    
    const newiteminit = {
        id: uuidv4(),        
        name:"",
        title:"",
        info:"",
        email:"",
        image:"",
        instagram:"",
        category:"staff",
    }
    const [newItem, setNewItem ] = useState(newiteminit)
    const [fetchLoading, setFetchLoading] = useState<boolean>(true)
    const [loading, setLoading] = useState<boolean>(false)

    const [message, setMessage] = useState("")
    const imageinit ={key:"",    type:"",    file:""}
    const [image, setImage] = useState<Iimage>(imageinit)  
    const [serviceJson, setStaffJson] = useState<Istaff []>([])
    const [serviceJsonReadOnly, setStaffJsonrReadOnly] = useState<Istaff [] >([])        
    const [alert, setAlert] = useState("")    
    const [tab, setTab] = useState(0)    
    const [removedFiles, setRemovedFiles] = useState<Istaff []>([] as Istaff[])
    const [updateData, setUpdateData] = useState(newiteminit)
    const [updateDialog, setUpdateDialog] = useState(false)
    const [triggerFetchData, setTriggerFetchData] = useState(false)

  
    //*Fetch json or Db data
    useEffect(() => {       
        async function func(){
     
            await dispatch(GetStaff({}))
                .unwrap()
                .then(res => {                  
                    if(res?.statusCode === 200){
                        
                        setStaffJson(res?.body?.data?.Staff)
                        setStaffJsonrReadOnly(res?.body?.data?.Staff)
                    }
                })
            
                setFetchLoading(false)
        }
        func()
        
        return () => {
            
        }
    }, [triggerFetchData])

    // Add a new download
    const UpdateStaffAPI = async() => {
        try{
            setLoading(true)                      
            const tokenres = await TokenRefresh()          
            if(tokenres !== 200) {           
                setLoading(false)
                return
            }        
         
            const oldItems = structuredClone(serviceJsonReadOnly)
            oldItems.push(newItem)
            await dispatch(UpdateStaff({                 
                updatedItem:newItem,
                oldItems: oldItems,
                updatedImage: image,                                  
            }))
                .unwrap()
                .then(res => {
                    if(res?.statusCode === 200 ){
                        setTimeout(() => {                            
                            setAlert("success")
                            setMessage(info?.Staff?.Snackbar_Success)
                            setLoading(false)
                            setTriggerFetchData(prev => !prev)
                        }, 2000);                         
                    }else{   
                        setLoading(false)
                        setAlert("error")
                        setMessage(res?.body?.message || info?.Staff?.Snackbar_Error)                                                                                                    
                    }                        
                })                                                                                        
        }
        catch (error) {
            setAlert("error")
            setMessage(info?.Staff?.Snackbar_Error)
            setLoading(false)
    
        }
      
    }
    // Removed Selected Staff API /deleteservice
    const DeleteStaffApi = async() => {
        setLoading(true)                      
        const tokenres = await TokenRefresh()          
        if(tokenres !== 200) {           
            setLoading(false)
            return
        }


     
        await dispatch(DeleteStaff({
            updatedItems: serviceJson,
            removedFiles: removedFiles,
        }))
            .unwrap()
            .then(res => {
                if(res?.statusCode === 200 ){
                    setTimeout(() => {                            
                        setAlert("success")
                        setMessage(info?.Staff?.Snackbar_Success)
                        setLoading(false)
                        setTriggerFetchData(prev => !prev)
                        setRemovedFiles([])
                    }, 2000);                         
                }else{   
                    setLoading(false)
                    setAlert("error")
                    setMessage( info?.Staff?.Snackbar_Error)                                                                                                    
                }                        
            })  
    }
  


    const HandleTextFields = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event?.target
        setNewItem(prev => 
            ({
                ...prev, [name]: value
            }))
    }

    //* Resets the serviceJson to its original state && setRemovedImagesArr is set to empty []
    const Reset = () => {              
        setRemovedFiles([])
        setStaffJson(serviceJsonReadOnly)           
    }
       
    //*  Open Close Delete and update Dialog         
    const CloseUpdateDialog = () => {
        setUpdateData(newiteminit)  
        setUpdateDialog(false)
    };              
    const OpenUpdateDialog = (params:GridRenderCellParams<Istaff>) => {             
        let rowIndex = serviceJson?.findIndex( row => row.id === params?.row?.id)
        setUpdateData(serviceJson?.[rowIndex])        
        setUpdateDialog(true)
    }


    interface Iinfotableprops {
        info: Iinfo
        rows: Istaff []
        setRows: (p:Istaff []) => void
        columns: GridColDef []
        setRemove: (p:React.SetStateAction<Istaff[]>) => void              
    }
    const InfoTable = (props:Iinfotableprops) => {
        const { rows, columns, info, setRows, setRemove } = props
        const [rowSelectionModel, setRowSelectionModel] = useState<GridRowSelectionModel>([]);
      
        const RemoveItems = () => {
            let tempRows:Istaff [] = structuredClone(rows)            
            rowSelectionModel?.forEach(tempId => {

                let rowIndex = tempRows?.findIndex( r => r?.id === tempId)
                let removedItem = tempRows.splice( rowIndex, 1)[0]

                
                setRemove(prev => [...prev, removedItem])
                
               
            })

            setRows(tempRows)
        }
        
        return(
            <Box sx={{width:"100%"}}>
                {/* Toolbar with Name of the table, selected items and delete button */}
                <Toolbar
            
                    sx={{
                    pl: { sm: 2 },
                    pr: { xs: 1, sm: 1 },
                    ...(rowSelectionModel.length > 0 && {
                        bgcolor: (theme) =>
                        alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
                    }),
                    }}
                >
                    {rowSelectionModel.length > 0 ? (
                    <Typography
                        sx={{ flex: '1 1 100%' }}
                        color="inherit"
                        variant="subtitle1"
                        component="div"
                    >
                        {rowSelectionModel.length} {info?.Staff?.Button_SelectImage}
                        
                    </Typography>
                    
                    ) : (
                    <Box className="flex__center-r">
                            
                        <Typography
                            sx={{ flex: '1 1 100%' }}
                            variant="h5"
                            id="tableTitle"
                            
                        >
                            {info?.Staff?.MainTitle}
                        
                        </Typography>
                    
                    </Box>
                    
                    
                    )}
        
                    {rowSelectionModel.length > 0 && (
                        <Tooltip title={info?.Staff?.DeleteTooltip}>
                            <IconButton  onClick={RemoveItems}>
                                <DeleteIcon/>
                            </IconButton>
                        </Tooltip>
                    )}

                </Toolbar>
           
                <DataGrid                
                    rows={rows || [] }
                    columns={columns}
                    initialState={{
                        pagination: {
                            paginationModel: {
                            pageSize: 5,
                            },
                        },
                    }}
                    pageSizeOptions={[5,10,100]}
                    checkboxSelection
                    disableRowSelectionOnClick
                    onRowSelectionModelChange={(newRowSelectionModel) => {
                        setRowSelectionModel(newRowSelectionModel);
                    }}
                    rowSelectionModel={rowSelectionModel}
                
         
                
                />
            </Box>
        )
    }
    const columns: GridColDef[] = [   
        {
            field: 'updatebtn',
            headerName: '',
            minWidth:150,
            renderCell: (params: GridRenderCellParams<Istaff>) => (                      
                <Button variant="contained" color='primary' endIcon={<EditIcon />} sx={{p:"0 0.5rem"}} onClick={()=>OpenUpdateDialog(params)}>
                    {info?.Staff?.Update_Button}
                </Button>
            ),
        },        
        {   
            field: 'title',
            headerName: 'Title',
            minWidth: 200,
            editable: false,            
            renderCell: (params: any) =>  {               
                return (
                    <Tooltip title={params.value} enterTouchDelay={0} leaveTouchDelay={30000}>
                        <span className="table-cell-trucate">{params.value}</span>
                    </Tooltip>
                    )
            }        
        },                                                     
    ];


   
    


    return (
        <Box className="flex__center-c">      

            {/* Selec which tab should be shown */}
            <Tabs value={tab} onChange={(event: React.SyntheticEvent, newValue: number)=> setTab(newValue) } centered textColor='primary' indicatorColor="primary">
                <Tab label={info?.Staff?.Tab_Create} sx={{color:"primary.main",}}/>
                <Tab label={info?.Staff?.Tab_Images} sx={{color:"primary.main",}}/>
            </Tabs>


            {/* TAB 0 => CREATE Staff */}
            {tab === 0 &&
                <Box className="flex__center-c">

                    {/* Submit Button */}
                    <Button
                            sx={{m: '1rem 0'}}
                            variant="contained"
                            color='success'
                            type='submit'
                            disabled={loading}
                            onClick={UpdateStaffAPI}
                            endIcon={<SaveIcon />}
                        >
                            {info?.Staff?.Button_Send}
                            {loading && (
                                <CircularProgress
                                    size={24}
                                    sx={{
                                        color: "white",
                                        position: 'absolute',
                                        top: '50%',
                                        left: '50%',
                                        marginTop: '-12px',
                                        marginLeft: '-12px',
                                    }}
                                />
                        )}
                    </Button>                    

                    <CssBaseline />      


                    {/* Textfields / add image / select type of service */}
                    <Container sx={{paddingTop:"2rem", display:'flex', flexDirection:'column', alignItems:'center'}} >        
                                                                                          

                        <DragDrop setMessage={setMessage} setAlert={setAlert} setImage={setImage} info={info} image={image} id={newItem?.id}
                            category={"staff"}
                            width={1080}
                            height={1080} 
                            type={"WEBP"} 
                            quality={80} 
                        />
                          
                        {/* Textfields, topic / message */}
                        <Paper sx={{p:"0.5rem",mt:"2rem"}}>

                        {/*     <Autocomplete                            
                                value={newItem?.category}
                                size='small'
                                disablePortal
                                id="combo-box-demo"
                                options={type}
                                sx={{ width: 200 }}
                                renderInput={(params) => <TextField {...params}  label="Kategori" />}
                                onChange={(event,value)=> value && setNewItem(prev => ({...prev, category: value}))}                            
                            /> */}

                            <TextField name="name"  onChange={HandleTextFields} value={newItem?.name} multiline fullWidth minRows={1} label={"Enter a name"} placeholder='tex Kalle Kula...' sx={{m:"2rem 0"}}>  

                            </TextField>
                            
                            <TextField name="title"  onChange={HandleTextFields} value={newItem?.title} multiline fullWidth minRows={1} label={"Enter a title"} placeholder='tex GYM MANAGER...' sx={{m:"2rem 0"}}>  

                            </TextField>
                            
                            <TextField name="info"  onChange={HandleTextFields} value={newItem?.info} multiline inputProps={{ maxLength: 300 }}  fullWidth minRows={4} label={"Enter info content"} sx={{m:"2rem 0"}}>  

                            </TextField>

                            <TextField name="instagram"  onChange={HandleTextFields} value={newItem?.instagram} multiline fullWidth minRows={4} label={"instagram link"} placeholder='https://instagram.com/....' sx={{m:"2rem 0"}}>  

                            </TextField>

                            <TextField name="email"  onChange={HandleTextFields} value={newItem?.email} multiline fullWidth minRows={4} label={"Enter an email to the person"} sx={{m:"2rem 0"}}>  

                            </TextField>

                            <Select
                                value={newItem?.category}                        
                                onChange={(event)=>setNewItem(prev => ({...prev,category: event?.target?.value}))}
                                size="small"
                                sx={{ height: 1,width:"90%"}}
                                native       
                                fullWidth                         
                            >
                                {(categories).map((category,i) => <option key={`option${category}${i}`}>{category}</option> )}                        
                            </Select>
                        </Paper>
                                                                        
                    </Container>


                    
                </Box>
            }          
            {/* TAB 2 => Update And/Or remove download item */}
            {tab === 1 && 
                <Box className="flex__center-c" width={"100vw"}>

                    {/* Save and reset buttons */}
                    <Box className="flex__center-c" sx={{m:"2rem 0"}}>
                        <Button
                            sx={{width: '180px',mb:"1rem"}}
                            variant="contained"
                            color='success'
                            disabled={loading || removedFiles.length <= 0}
                            onClick={DeleteStaffApi}
                            endIcon={<SaveIcon />}
                        >
                        {info?.Staff?.Button_Delete}
                        {loading && (
                            <CircularProgress
                                size={24}
                                sx={{
                                color: "teal",
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                                }}
                            />
                        )}
                        </Button>
                        <Button variant="outlined" color="warning" sx={{width: '180px'}} onClick={Reset}>
                            {info?.Staff?.Button_Reset}
                        </Button>
                    </Box>

                    <InfoTable 
                        info={info}
                        rows={serviceJson}
                        setRows={setStaffJson}
                        columns={columns}
                        setRemove={setRemovedFiles}                                               
                    />

                    <UpdateDialogImage                                      
                        updateDialog={updateDialog}
                        CloseUpdateDialog={CloseUpdateDialog}
                        row={updateData}
                        rows={serviceJsonReadOnly}
                        info={info}
                        setTriggerFetchData={setTriggerFetchData}
                    />    


                 
                </Box>
            }
   
            <SnackbarComponent message={message} alert={alert} setMessage={setMessage} setAlert={setAlert} />

            <SimpleBackdropLoading openbackdrop={fetchLoading} />    

        </Box>   
    )
    
}

export default Staff